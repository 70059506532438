<template>
  <main-map>
  </main-map>
  <div v-for="(route, index) in listRoute" :key="index">
    <points-map :positions="route" :color="colorRoute[route.clave_ruta]" />
    <route-map  :positions="route" :color="colorRoute[route.clave_ruta]" />
  </div>
  <div class="row q-col-gutter-sm tree-list">
    <q-tree :nodes="simple" node-key="key" tick-strategy="leaf" v-model:ticked="ticked">
      <template v-slot:default-header="prop">
        <div>
          <div>{{ prop.node.name || prop.node.key }}</div>
        </div>
      </template>
    </q-tree>
  </div>
</template>

<script setup>
import {
  defineAsyncComponent, isProxy, onBeforeMount, onMounted, ref, toRaw, watch,
} from 'vue';
import preloadImages from '@/components/preload-image';

const MainMap = defineAsyncComponent(() => import('@/components/MainMap.vue'));
const PointsMap = defineAsyncComponent(() => import('@/components/PointsMap.vue'));
const RouteMap = defineAsyncComponent(() => import('@/components/RouteMap.vue'));

const info = ref([]);
const ticked = ref([]);
const simple = ref([]);
const listRoute = ref([]);
const colorRoute = ref({});

const generateRandomColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

onBeforeMount(() => {
  preloadImages();
});

const groupByAddressAndShift = (data) => {
  const groupedAddresses = {
    ENTRADAS: [],
    SALIDAS: [],
  };

  // Group by address
  groupedAddresses.ENTRADAS = data.filter((route) => route.direccion_ruta === 'ENTRADA');
  groupedAddresses.SALIDAS = data.filter((route) => route.direccion_ruta === 'SALIDA');
  // data.forEach(route => {
  //   if (route.direccion_ruta === 'ENTRADA') {
  //     groupedAddresses.ENTRADAS.push(route);
  //   } else if (route.direccion_ruta === 'SALIDA') {
  //     groupedAddresses.SALIDAS.push(route);
  //   }
  // });

  // Function to group routes by shift
  const groupByShift = (routes) => {
    const grouped = {};

    routes.forEach((route) => {
      if (!grouped[route.turno_ruta]) {
        grouped[route.turno_ruta] = [];
      }
      grouped[route.turno_ruta].push(route);
    });

    return Object.entries(grouped).map(([shift, routes]) => ({
      key: `Turno: ${shift}`,
      children: routes.map((route) => ({ key: route.clave_ruta, name: route.nombre_ruta })),
    }));
  };

  // Transform into the required structure
  return [
    {
      key: 'Rutas: Foxconn',
      children: [
        {
          key: 'Entadas',
          children: groupByShift(groupedAddresses.ENTRADAS),
        },
        {
          key: 'Salidas',
          children: groupByShift(groupedAddresses.SALIDAS),
        },
      ],
    },
  ];
};

onMounted(async () => {
  try {
    const response = await fetch('https://rutasbusmen.geovoy.com/api/v1/rutasParadas', {
      method: 'POST',
      body: new URLSearchParams('grupo=Foxconn'),
    });

    if (response.ok) {
      const result = await response.json();
      info.value = result;
      simple.value = groupByAddressAndShift(result);
    }
  } catch (e) {
    console.error(e);
  }
});

const doesObjectExist = (id) => (colorRoute.value[id] === undefined);

watch(ticked, (data) => {
  const convertToArray = Object.values(data);
  let list;
  if (isProxy(info.value)) list = toRaw(info.value);
  else list = info.value;
  // eslint-disable-next-line
  listRoute.value = list.filter((route) => {
    return ([route.clave_ruta].some((item) => convertToArray.includes(item)));
  });

  if (convertToArray.length > Object.keys(colorRoute.value).length) {
    // eslint-disable-next-line
    convertToArray.map((id) => {
      if (doesObjectExist(id)) colorRoute.value[id] = generateRandomColor();
    });
  } else {
    // eslint-disable-next-line
    Object.keys(colorRoute.value).map((key) => {
      const index = convertToArray.findIndex((item) => item === key);
      if (index === -1) delete colorRoute.value[key];
    });
  }
});

</script>

<style scoped>
.tree-list {
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
  width: 16rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  max-height: 100vh;
  overflow-y: auto;
}
</style>
