/* eslint-disable */
import 'quasar/dist/quasar.css';
import iconSet from 'quasar/icon-set/mdi-v7.js';
import lang from 'quasar/lang/es.js';
import '@quasar/extras/mdi-v7/mdi-v7.css';

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
  },
  lang,
  iconSet,
};
