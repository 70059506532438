import defaultSvg from '@/assets/default.svg';

const loadImage = (url) => new Promise((imageLoaded) => {
  const image = new Image();
  image.onload = () => imageLoaded(image);
  image.src = url;
});

// Imagenes
export const mapIcons = {
  default: defaultSvg,
};
// Llaves de cada imagen para llamarlo desde otro componente
// eslint-disable-next-line
export const mapIconsKey = (category) => (mapIcons.hasOwnProperty(category) ? category : 'default');
// Guardado de imagenes para precarga
export const mapImages = {};
// Cargar imagenes
export default async () => {
  await Promise.all(Object.keys(mapIcons).map(async (category) => {
    const results = [];
    results.push(loadImage(mapIcons[category]).then((icon) => {
      mapImages[`${category}`] = icon;
    }));
    await Promise.all(results);
  }));
};
